<template>
  <vue-good-table
    mode="remote"
    :columns="columnsFormatted"
    :rows="rowsFormatted"
    :row-style-class="getRowStyle"
    :totalRows="totalRows"
    :select-options="{
       enabled: enableSelect,
       selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
       selectionInfoClass: 'custom-class',
       selectionText: 'rows selected',
       clearSelectionText: 'clear',
       disableSelectInfo: true, // disable the select info panel on top
       selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
     }"
    :pagination-options="{
       enabled: enablePagination,
       perPage: perPage,
     }"
    @on-sort-change="sortChange"
    @on-selected-rows-change="selectedRowsChange"
  >

    <template #table-column="props">
      <span :id="'thead-' + props.column.field" class="text-nowrap thead-text-nowrap">
        {{ common.getI18n(props.column.label) }}
      </span>
      <b-tooltip
        :target="'thead-' + props.column.field"
        :delay="800"
      >
        {{ common.getI18n(props.column.label) }}
      </b-tooltip>
    </template>

    <template #table-row="props">
      <span v-if="props.column.field === 'row_index'">
        {{ props.index + 1 + perPageData * (currentPage - 1) }}
      </span>

      <template v-else-if="props.column.type === 'slot'">
        <slot :name="props.column.slot" :props="props" :rows="rows"></slot>
      </template>

      <div
        v-else-if="props.column.changeHTML"
        v-html="props.column.changeHTML(props.formattedRow[props.column.field], props.row)"
      ></div>

      <template v-else-if="props.column.type === 'select'">
        <v-select
          appendToBody
          label="text"
          :value="props.formattedRow[props.column.field]"
          :options="props.column.options"
          :reduce="text => text.value"
          :clearable="false"
          :searchable="false"
          :style="props.column.style"
          @option:selecting="rowSelecting(props.column.selectedFun, props.row)"
          @option:selected="rowSelected"
        >
          <template #no-options>
            {{ $t('common.no_options') }}
          </template>
        </v-select>
      </template>

      <template v-else-if="props.column.field === 'action'">
        <template v-for="(action, index) in actionOptions">
          <button-permission
            v-if="checkButtonShow(action, props.row)"
            :disabled="checkButtonDisabled(action, props)"
            size="sm"
            :variant="action.variant"
            class="mt-1 ml-1"
            @click="runTableAction(action.fun, rows[props.index])"
            @mousedown="mousedownAction(rows[props.index])"
            :permission="action.permission"
          >
            {{ $t(action.text) }}
          </button-permission>
        </template>
      </template>

      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>

    </template>

    <template #pagination-bottom="props">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1 mr-1">
          <v-select
            v-if="perPageDropdownEnabled"
            :multiple="false"
            label="text"
            :options="perPageDropdown.map((v) => ({
              text: String.prototype.replace.apply($t('common.rows_per_page'), ['{size}', v]),
              value: v
            }))"
            v-model="perPageData"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="false"
            class="font-size-16px"
          >
            <template #no-options>
              {{ $t('common.no_options') }}
            </template>
          </v-select>
        </div>
        <div class="mb-0 mt-1">
          <b-pagination
            class="m-0"
            :value="currentPage"
            :total-rows="props.total"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            @page-click="pageClick"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </div>
    </template>

    <template slot="emptystate">
      <div v-if="!permission" class="text-center">{{ $t('permission.no_view') }}</div>
      <div v-else class="text-center">{{ $t('common.no_data') }}</div>
    </template>

  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import {
  BPagination,
  BButton
} from 'bootstrap-vue'
import common from "@/common";
import ButtonPermission from "@/components/ButtonPermission";
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: "TableContent",
  components: {
    ButtonPermission,
    VueGoodTable,
    BPagination,
    BButton,
    vSelect,
    common
  },
  directives: {
    Ripple,
  },
  props: {
    columns: { type: Array, default: () => [] },
    rows: { type: Array },
    totalRows: { type: Number },
    currentPage: { type: Number },
    enableSelect: { type: Boolean, default: false },
    actionOptions: { type: Array, default: () => [] },
    permission: { type: Boolean, default: true },

    enablePagination: { type: Boolean, default: true },
    perPageDropdownEnabled: { type: Boolean, default: false },
    perPage: { type: Number, default: common.config.table_per_page },
    perPageDropdown: { type: Array, default: () => common.config.table_per_page_dropdown },
  },
  data() {
    return {
      change_fun: '',
      change_row: {},
      common: common,
    }
  },
  computed: {
    perPageData: {
      get() {
        return this.perPage
      },
      set(newValue) {
        this.$emit('perPageChange', newValue)
      }
    },
    rowsFormatted() {
      const tempRows = _.cloneDeep(this.rows)
      this.columns.forEach(col => {
        if (typeof (col.change) === 'function') {
          tempRows.forEach((row, rowIndex) => {
            row[col.field] = col.change(this.rows[rowIndex][col.field], this.rows[rowIndex])
          })
        }
      })
      return tempRows
    },
    columnsFormatted: function () {
      let newColumns = []
      this.columns.forEach((col, _i, _arr) => {
        // 設置 acticon 列的表格 <td> padding-left 和 padding-top 為 0，用於抵消其內部按鈕的 margin-left 和 margin-top，並且把其他 <td> 的 padding 設為 .p-1 的 padding
        let newCol = (
          col.field === 'action'
            ? Object.assign({ tdClass: 'pt-0 pr-1 pb-1 pl-0' }, col)
            : Object.assign({ tdClass: 'p-1' }, col)
        )

        // 设置序号列默认居右
        if (newCol.field === 'row_index') newCol.type = newCol.type ?? 'number'
        newColumns.push(newCol)
      })
      return newColumns
    }
  },
  mounted() {
    for (const i in this.columns) {
      this.columns[i]['sortFn'] = function () {
        return false
      }
    }
  },
  methods: {
    pageClick: function (event, page) {
      this.$emit('pageChange', page)
    },
    pageChange: function (page) {
      // this.$emit('pageChange', page)
    },
    runTableAction: function (fun, row) {
      this.$emit('runTableAction', fun, row)
    },
    sortChange: function (params) {
      const isDesc = params[0].type === 'desc'
      this.$emit('sortChange', { orderBy: params[0].field, isDesc: isDesc })
    },
    selectedRowsChange: function (params) {
      this.$emit('selectedRowsChange', params)
    },
    getRowStyle: function (row) {
      if (row.selected) {
        return 'row-selected'
      }
      return ''
    },
    mousedownAction: function (row) {
      this.$emit('mousedownAction', row)
    },
    checkButtonShow: function (action, row) {
      if (typeof (action.show) === 'string') {
        return eval(action.show)
      } else if (typeof (action.show) === 'function') {
        return action.show(row)
      }
      return true
    },
    checkButtonDisabled(action, props) {
      if (typeof (action.check_disable) === 'function') {
        return action.check_disable(this, props)
      }
      return false
    },
    rowSelecting: function (selectedFun, row) {
      this.change_fun = selectedFun
      this.change_row = row
    },
    rowSelected: function (option) {
      if (option.value === '') return

      this.$emit('runTableAction', this.change_fun, { row: this.change_row, option: option })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .thead-text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
