<template>
  <b-button
    :block="block"
    :pill="pill"
    :pressed="pressed"
    :size="size"
    :squared="squared"
    :type="type"
    :variant="variant"
    @click="click"
    @mousedown="mousedown"
    v-b-tooltip.hover.top="tooltip"
    :disabled="isDisabled"
  >
    <slot></slot>
  </b-button>
</template>

<script>
import {BButton, VBTooltip} from 'bootstrap-vue'
import common from "@/common";

export default {
  name: "ButtonPermission",
  components: {
    BButton
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    block: {type: Boolean, default: false},
    pill: {type: Boolean, default: false},
    pressed: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    size: {type: String, default: ''},
    squared: {type: Boolean, default: false},
    type: {type: String, default: ''},
    variant: {type: String, default: ''},
    permission: {type: String}
  },
  data() {
    return {
      tooltip: '',
      btn_variant: ''
    }
  },
  computed: {
    isDisabled() {
      return !common.checkPermission(this.permission) || this.disabled
    }
  },
  mounted() {
    this.tooltip = !common.checkPermission(this.permission) ? common.getI18n('permission.no_operate') : ''
    this.btn_variant = this.variant
  },
  methods: {
    click: function () {
      if (this.disabled) return

      this.$emit('click')
    },
    mousedown: function () {
      this.$emit('mousedown')
    }
  }
}
</script>

<style scoped>

</style>
